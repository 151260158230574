var Flickity = require('flickity');
require('flickity-imagesloaded');

export function init() {
    initFlickity();
}

function initFlickity() {
    var eHomeNewsSlider = document.getElementById('home-news-slider');
    if (eHomeNewsSlider) {
        var homeslider = new Flickity(eHomeNewsSlider, {
            cellAlign: 'center',
            contain: false,
            prevNextButtons: false,
            pageDots: true,
            wrapAround: true,
            setGallerySize: true,
            imagesLoaded: true
        });
    }

    var eHomeHeroSlider = document.getElementById('homeslider');
    if (eHomeHeroSlider) {
        var homeHeroSlider = new Flickity(eHomeHeroSlider, {
            prevNextButtons: false,
            lazyLoad: 1,
            bgLazyLoad: true,
            pageDots: false,
            wrapAround: true,
            setGallerySize: false,
            autoPlay: 3000,
            pauseAutoPlayOnHover: false
        });
    }


    var elGallerySlider = document.getElementById('gallery-slider');
    if (elGallerySlider) {
        var galleryslider = new Flickity(elGallerySlider, {
            prevNextButtons: true,
            lazyLoad: 2,
            pageDots: true,
            wrapAround: true,
            setGallerySize: true,
            initialIndex: 1
        });
    }

    var elVideoSlider = document.getElementById('video-slider');
    if (elVideoSlider) {
        var videoslider = new Flickity(elVideoSlider, {
            prevNextButtons: true,
            lazyLoad: 2,
            pageDots: false,
            wrapAround: true,
            setGallerySize: true,
            cellSelector: '.video-slide',
            cellAlign: 'left'
        });
    }

}