import $ from 'jquery';
export function init() {

    // MODX will insert this element if TOC is requested
    let holder = document.getElementById('toc-outer');
    if (holder) {
        let sections = getH2Elements();
        let toc = '';        
        if (sections.length) {
            toc = buildToc(sections);
            holder.appendChild(toc);
            new Foundation.SmoothScroll( $('ul.toc'), {'offset':50} );
            new Foundation.SmoothScroll( $('.scrollTop'), {'offset':50} );
        } else {
            console.log("No titles");
        }
    } else {
        console.log("No TOC holder");
    }
}

function getH2Elements() {
    let elHeaders = document.getElementsByTagName('h2');
    let titles = [];
    if (elHeaders.length) {
        elHeaders.forEach( function(header, idx){
            let anchorName = 'h2_' + idx;
            let anchor = document.createElement('a');
            anchor.setAttribute('name', anchorName);
            header.setAttribute('id', anchorName);
            //header.insertAdjacentElement("beforebegin", anchor);

            titles.push({
                "text":header.innerText, 
                "anchor": anchorName}
            );
        }); 
    }
    return titles;
} 

function buildToc(titles) {
    let toc = document.createElement('ul');
    toc.className = 'toc';
    // toc.setAttribute('data-smooth-scroll', 'data-smooth-scroll');
    titles.forEach(function(title){
        let item = document.createElement('li');
        let anchor = document.createElement('a');
        anchor.innerText = title.text;
        anchor.setAttribute('href', '#' + title.anchor);
        item.appendChild(anchor);
        toc.appendChild(item);
    });
    return toc;
}