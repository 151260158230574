import $ from 'jquery';

export function init(){

    if ( $('body.news').length ){

        var parent = $('.news-summary-list').data('newspage');

        addLoadMoreButton(parent);
        
        $('body.news').on('click', '.load-more', function(e) {
            e.preventDefault();
        
            var $this = $(this);
        
            var load   = $this.data('current-page') + 1;
            var parent = $this.data('parent');
        
            $.ajax({
                type: "GET",
                url: "/api/paginate",
                data: {page: load, parent: parent},
                success: function (response) {
                    response = $.parseJSON(response);
        
                    if(response.success) {
                        $(".news-summary-list").append(response.page);
        
                        // Update the page attribute
                        $this.data('current-page', load);
                        // Foundation.reInit('equalizer');
                    } else {
                        $this.after(response.page);
                        $this.remove();
                    }
                },
                error: function (jqXHR, textStatus, errorThrown) {
                    // Do something if there was an error
                }
            });
        });
    }
}

function addLoadMoreButton(parentId) {
    let numDisplayedNewsItems = $('.news-item').length;
    let maxNewsItems = $('#total-news-items').val();
    let $loadMoreHolder = $('#load-more-holder');
    let $button = $('<button class="load-more small button" data-current-page="1" data-parent="' + parentId + '">Load more</button>');

    if ( numDisplayedNewsItems < maxNewsItems ) {
        $loadMoreHolder.append($button);
    }
}